<template>
  <div class="search-box">
    <template v-for="item in searchArr" :key="item.key">
      {{ item.label }}：
      <template v-if="item.type === 'select'">
        <el-select v-model="item.value" size="small" :placeholder="'请选择' + item.label" clearable>
          <el-option
            v-for="item in item.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
      <template v-else-if="item.type === 'input'">
        <el-input
          v-model="item.value"
          size="small"
          :placeholder="'请输入' + item.label"
          clearable
        />
      </template>
      <template v-else>
        <el-input
          v-model="item.value"
          size="small"
          :placeholder="'请输入' + item.label"
          clearable
        />
      </template>
    </template>
    <el-button type="primary" size="small" round @click="search"
      >查询</el-button
    >
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'
const props = defineProps({
  form: {
    type: Object,
    required: true
  }
})
const searchArr = ref(props.form)

const emits = defineEmits(['search']) // 定义emits
function search() {
  const params = searchArr.value
    .filter((item) => item.value)
    .reduce((obj, cur, index) => {
      obj[cur.key] = cur.value
      return obj
    }, {})
  emits('search', params)
}
</script>

<style lang="scss" scoped>
.el-input, .el-select {
  width: 250px;
  margin-right: 15px;
}
.search-box {
  padding-bottom: 20px;
}
</style>
