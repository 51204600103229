import axios from 'axios'
import { divide100, divide } from '@/utils/math'

export const download = (res, type = 'application/pdf', fileName = '') => {
  // 创建blob对象，解析流数据
  const blob = new Blob([res], {
    // 如何后端没返回下载文件类型，则需要手动设置：type: 'application/pdf;chartset=UTF-8' 表示下载文档为pdf，如果是word则设置为msword，excel为excel
    type
  })
  const a = document.createElement('a')
  // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
  const URL = window.URL || window.webkitURL
  // 根据解析后的blob对象创建URL 对象
  const herf = URL.createObjectURL(blob)
  // 下载链接
  a.href = herf
  // 下载文件名,如果后端没有返回，可以自己写a.download = '文件.pdf'
  a.download = fileName
  console.log(fileName)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  // 在内存中移除URL 对象
  window.URL.revokeObjectURL(herf)
}

async function downloadPdf(url, name = '') {
  const res = await axios.get(
    url,
    {
      baseURL: '',
      // 设置返回数据类型，这里一定要设置，否则下载下来的pdf会是空白,也可以是`arraybuffer`
      responseType: 'blob'
    }
  )
  console.log(res)
  // 获取在response headers中返回的下载文件类型
  const type = res.headers['content-type']
  console.log('type', type)

  // 获取在response headers中返回的下载文件名因为返回文件名是通过encodeURIComponent()函数进行了编码，因此需要通过decodeURIComponent()函数解码

  const fileName = name || decodeURIComponent(res.headers['file-name']) || ''
  // 调用封装好的下载函数
  download(res.data, type, fileName)
}

export async function downloadFile(url, fileName = '') {
  console.log('Downloading file ' + url)
  if (!url) {
    return
  }
  downloadPdf(url, fileName)
  // if (url.indexOf('.pdf') !== -1) {
  //   downloadPdf(url)
  // } else {
  //   const downloadElement = document.createElement('a')
  //   const href = url // 创建下载的链接
  //   downloadElement.href = href
  //   document.body.appendChild(downloadElement)
  //   downloadElement.click() // 点击下载
  //   document.body.removeChild(downloadElement) // 下载完成移除元素
  // }
}

// export function divide100(value) {
//   if (value || value === 0) {
//     return value / 100
//   } else {
//     return '/'
//   }
// }

export function dataConversion(source) {
  return (model) => {
    let value = model.keys.reduce((value, current) => value + (source[current] || ''), '')
    if (model.type === 'number') {
      value = divide100(value)
    } else if (model.type === 'thousandNumber') {
      value = divide(value, 1000)
    } else if (model.type === 'image') {
      value = value.split(';').filter(item => item)
    }
    return {
      ...model,
      value,
      valueUnit: value + (model.unit || '')
    }
  }
}
