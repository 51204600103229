import { ref, computed } from 'vue'

export function tableSearchPagination() {
  /**
 * 表格搜索
 */
  const searchParams = ref({})
  const search = (form) => {
    searchParams.value = form
  }

  /**
   * 分页参数
   */
  const pageIndex = ref(1)
  const pageSize = ref(15)
  const total = ref(0)

  /**
   * size 改变触发
   */
  const handleSizeChange = (currentSize) => {
    pageSize.value = currentSize
    pageIndex.value = 1
  }

  /**
   * 页码改变触发
   */
  const handleCurrentChange = (currentPage) => {
    pageIndex.value = currentPage
  }

  const searchPaginationParams = computed(() => ({
    ...searchParams.value,
    pageSize: pageSize.value,
    pageIndex: pageIndex.value
  }))

  return {
    pageIndex: pageIndex,
    pageSize: pageSize,
    total,
    handleSizeChange,
    handleCurrentChange,
    search,
    searchPaginationParams
  }
}
