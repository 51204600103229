import request from '@/utils/request'

/**
 * 获取维保企业列表数据
 */
export const findProjectList = params => {
  return request({
    url: '/check/findProjectList',
    params
  })
}

/**
 * 获取维保报告列表数据
 */
export const findReportList = data => {
  return request({
    method: 'POST',
    url: '/maintenance/findPlanManagementReportList',
    data
  })
}

/**
 * 获取维保报告合同数据
 */
export const findContract = params => {
  return request({
    url: '/maintenance/findContract',
    params
  })
}

/**
 * 获取维保报告会议数据
 */
export const findConference = params => {
  return request({
    url: '/maintenance/findConference',
    params
  })
}

/**
 * 获取维保报告会议数据
 */
export const modifyPlanWhole = data => request({
  url: '/maintenance/modifyPlanWhole',
  method: 'POST',
  data
})
