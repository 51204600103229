export function divide100(value) {
  if (value || value === 0) {
    return value / 100
  } else {
    return '/'
  }
}

export function divide(value, Divisor) {
  if (value || value === 0) {
    return value / Divisor
  } else {
    return '/'
  }
}
